import {CSSProperties, PropsWithChildren, useCallback, useEffect, useRef, useState} from "react";

type AspectRatioBoxProps = PropsWithChildren<{
    aspectRatio: number
}> & (
    {
        width: CSSProperties["width"]
        height?: undefined
    } |
    {
        width?: undefined
        height: CSSProperties["height"]
    }
    )

export default function AspectRatioBox({aspectRatio, width, height, children}: AspectRatioBoxProps) {
    const div = useRef<HTMLDivElement | null>(null)

    const [w, setW] = useState<CSSProperties["width"]>(width)
    const [h, setH] = useState<CSSProperties["height"]>(height)

    const resize = useCallback(() => {
        if (div.current) {
            if (width !== undefined) {
                setH(div.current.clientWidth * (1 / aspectRatio))
            } else if (height !== undefined) {
                setW(div.current.clientHeight * aspectRatio)
            }
        }
    }, [width, height, aspectRatio])

    useEffect(() => {
        const observer = new ResizeObserver(resize)
        observer.observe(div.current!)
        resize()
        return () => observer.disconnect()
    }, [resize])

    return <div
        ref={div}
        style={{
            width: w,
            height: h
        }}
        children={children}
    />
}