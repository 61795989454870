import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {THEME} from "./utils/theme";
import {BrowserRouter, Route} from "react-router-dom";
import "./index.css"
import { QueryParamProvider } from 'use-query-params';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={Route}>
                <ThemeProvider theme={THEME}>
                    <CssBaseline/>
                    <App/>
                </ThemeProvider>
            </QueryParamProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
