import React, {ButtonHTMLAttributes, ComponentProps} from "react";
import {AsyncState} from "react-async";
import {LoadingButton} from "@mui/lab";

export type AsyncButtonProps = {
    state: AsyncState<any>
    argsProvider?: () => any[]
} & ComponentProps<typeof LoadingButton> & Omit<ButtonHTMLAttributes<any>, "title">

export default function AsyncButton({state, argsProvider, ...buttonProps}: AsyncButtonProps) {
    return <LoadingButton
        loading={state?.isPending}
        onClick={() => {
            const args = argsProvider ? argsProvider() : []
            state?.run(...args)
        }}
        {...buttonProps}
    />
}