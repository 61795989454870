import {DialogState} from "../utils/useDialogState";
import {Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField} from "@mui/material";
import {useAsync} from "react-async";
import {useEffect, useState} from "react";
import {DBKpi, isKpiQualitative, isKpiQuantitative} from "shared/dist/kpi";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {it} from "date-fns/locale";
import {DateTimePicker, LocalizationProvider} from "@mui/lab";
import AsyncButton from "../components/async/AsyncButton";
import {Schedule} from "@mui/icons-material";
import {useLoginInfo} from "../App";
import CollapsibleGridItem from "../components/CollapsibleGridItem";
import AsyncErrorAlert from "../components/async/AsyncErrorAlert";
import {serverFetch} from "../utils/fetch";
import {KpiQualitativeEnum} from "@prisma/client"


export default function KpiAddMeasurementDialog({state, onMeasurementAdded}: {
    state: DialogState<DBKpi>
    onMeasurementAdded: () => void
}) {
    const login = useLoginInfo()
    const [time, setTime] = useState<Date | null>(null)
    const [value, setValue] = useState<number | null>(null)
    const [enumValue, setEnumValue] = useState<KpiQualitativeEnum | null>(null)

    const add = useAsync({
        deferFn: async () => {
            if (state.data) {
                if (isKpiQuantitative(state.data)) {
                    return serverFetch(`/api/kpi/quantitative/${state.data.id}/measurements`, {
                        login,
                        method: "POST",
                        bodyFormat: "json",
                        body: {time, value}
                    })
                } else if (isKpiQualitative(state.data)) {
                    return serverFetch(`/api/kpi/qualitative/${state.data.id}/measurements`, {
                        login,
                        method: "POST",
                        bodyFormat: "json",
                        body: {time, enum: enumValue!.id}
                    })
                }
            }
        },
        onResolve: () => {
            state.close()
            onMeasurementAdded()
        }
    })

    const setData = add.setData
    useEffect(() => {
        if (state.isOpen) {
            setTime(null)
            setValue(null)
            setData(undefined)
        }
    }, [state.isOpen, setTime, setValue, setData])

    const quantitativeOk = state.isOpen && isKpiQuantitative(state.data) && value !== null
    const qualitativeOk = state.isOpen && isKpiQualitative(state.data) && enumValue !== null
    const ok = time !== null && !isNaN(time.getTime()) && (qualitativeOk || quantitativeOk)

    return <Dialog
        fullWidth
        maxWidth="sm"
        open={state.isOpen}
        onClose={state.close}
    >
        {state.isOpen && <>
            <DialogTitle>Aggiungi misurazione</DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={2} sx={{pt: 1}}>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={it}>
                            <DateTimePicker
                                renderInput={(props) => <TextField
                                    fullWidth
                                    {...props}
                                    InputProps={{
                                        ...props.InputProps,
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={() => setTime(new Date(new Date().setMilliseconds(0)))}>
                                                <Schedule/>
                                            </IconButton>
                                            {props.InputProps?.endAdornment}
                                        </InputAdornment>
                                    }}
                                />}
                                label="Data e ora misurazione"
                                inputFormat="dd/MM/yyyy HH:mm:ss.SSS"
                                mask="__/__/____ __:__:__.___"
                                value={time}
                                onChange={setTime}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        {isKpiQuantitative(state.data) && <>
                            <TextField
                                fullWidth
                                type="number"
                                label="Valore misurazione"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{state.data.kpiQuantitative.unit}</InputAdornment>,
                                }}
                                value={value ?? ""}
                                onChange={e => {
                                    if (e.target.value === "") {
                                        setValue(null)
                                    } else {
                                        setValue(parseFloat(e.target.value))
                                    }
                                }}
                                onKeyDown={e => {
                                    if (e.key === "Enter") {
                                        add.run()
                                    }
                                }}
                            />
                        </>}
                        {isKpiQualitative(state.data) && <>
                            <Autocomplete
                                fullWidth
                                value={enumValue}
                                onChange={(e, value) => setEnumValue(value)}
                                options={state.data.kpiQualitative.enums}
                                getOptionLabel={e => e.value}
                                renderInput={(params) => <TextField {...params} label="Valore"/>}
                            />
                        </>}
                    </Grid>
                    <AsyncErrorAlert
                        state={add}
                        createWrapper={params => <CollapsibleGridItem params={params}/>}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={state.close}>Annulla</Button>
                <AsyncButton state={add} onClick={add.run} disabled={!ok}>
                    Aggiungi
                </AsyncButton>
            </DialogActions>
        </>}
    </Dialog>
}