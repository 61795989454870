import React, {createContext, useCallback, useContext} from 'react';
import {useCookies} from 'react-cookie';
import {Route, Switch, useHistory} from 'react-router-dom';
import {CookieSetOptions} from 'universal-cookie';
import queryString from "query-string";
import {useAsync} from 'react-async';
import {LoginData} from "shared/dist/auth";
import {serverFetch} from "./utils/fetch";
import AsyncPage from "./components/async/AsyncPage";
import Login, {RedirectToLogin} from './pages/Login';
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Survey from "./pages/Survey";
import KPIs from "./pages/kpis/KPIs";

const COOKIE_OPTIONS: CookieSetOptions = {
    path: "/",
    secure: true,
    httpOnly: false
}

export type LoginInfo = {
    token: string
    logout: (error?: string) => void
    data: LoginData
}

const LoggedContext = createContext<LoginInfo | undefined>(undefined)

export default function App() {
    const [cookies, setCookie, removeCookie] = useCookies(["login_token"])
    const loginToken = cookies["login_token"]
    const history = useHistory()

    const logout = useCallback((error?: string) => {
        removeCookie("login_token", COOKIE_OPTIONS)

        history.push("/login?" + queryString.stringify({error}))
    }, [history, removeCookie])

    const loginInfo = useAsync<LoginInfo | null>(useCallback(async () => {
        if (loginToken) {
            const partialLoginInfo = {
                token: loginToken,
                logout
            }
            const response = await serverFetch("/api/auth/data", {
                login: partialLoginInfo
            })
            const data = (await response.json()) as LoginData

            return {
                ...partialLoginInfo,
                data
            }
        } else {
            return null
        }
    }, [logout, loginToken]))

    return <AsyncPage state={loginInfo} text="Caricamento informazioni di login...">
        {loginInfo => {
            if (loginInfo) {
                return <LoggedContext.Provider value={loginInfo}>
                    <Switch>
                        <Route path="/" exact>
                            <Home />
                        </Route>
                        <Route path="/survey" exact>
                            <Survey />
                        </Route>
                        <Route path="/kpis" exact>
                            <KPIs />
                        </Route>
                        <Route>
                            <NotFound/>
                        </Route>
                    </Switch>
                </LoggedContext.Provider>
            } else {
                return <Switch>
                    <Route path="/login">
                        <Login onLoggedIn={token => {
                            setCookie("login_token", token, COOKIE_OPTIONS)
                        }}/>
                    </Route>
                    <Route>
                        <RedirectToLogin/>
                    </Route>
                </Switch>
            }
        }}
    </AsyncPage>
}

export function useLoginInfo() {
    const info = useContext(LoggedContext)
    if (info) {
        return info
    } else {
        throw new Error("Not in logged context")
    }
}