import {Grid, GridProps, Typography, useTheme} from "@mui/material";
import {SvgIconComponent} from "@mui/icons-material";
import Centered from "./Centered";
import {ReactElement} from "react";

type SingleChild = ReactElement<GridProps>|undefined
export type MessageWithIconChildren = SingleChild|SingleChild[]

export default function MessageWithIcon({Icon, message, children}: {
    Icon: SvgIconComponent
    message: string
    children?: MessageWithIconChildren
}) {
    const theme = useTheme()
    return <Centered>
        <Grid container direction="column" spacing={2} alignItems="center">
            <Grid item>
                <Icon sx={{fontSize: 120, color: theme.palette.grey["500"]}}/>
            </Grid>
            <Grid item>
                <Typography>
                    {message}
                </Typography>
            </Grid>
            {children}
        </Grid>
    </Centered>
}