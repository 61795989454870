import {DialogState} from "../utils/useDialogState";
import {DBKpi, isKpiQualitative, isKpiQuantitative} from "shared/dist/kpi";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    Link,
    Radio,
    RadioGroup,
    styled,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {DateTimePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {it} from 'date-fns/locale'
import {useAsync} from "react-async";
import AsyncErrorAlert from "../components/async/AsyncErrorAlert";
import CollapsibleGridItem from "../components/CollapsibleGridItem";
import AsyncButton from "../components/async/AsyncButton";
import {serverFetch} from "../utils/fetch";
import {useLoginInfo} from "../App";
import {Add} from "@mui/icons-material";
import WrappableCollapse from "../components/WrappableCollapse";


enum Type {
    Quantitative = "quantitative",
    Qualitative = "qualitative"
}

export default function KpiDialog({state, onSaved}: {
    state: DialogState<DBKpi | null>
    onSaved: () => void
}) {
    const login = useLoginInfo()

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [type, setType] = useState<Type>(Type.Quantitative)

    // Quantitative
    const [unit, setUnit] = useState("")
    const [targetMin, setTargetMin] = useState<number | null>(0)
    const [targetMax, setTargetMax] = useState<number | null>(0)
    const [targetDate, setTargetDate] = useState<Date | null>(null)

    // Qualitative
    const [currentEnum, setCurrentEnum] = useState<string>("")
    const [enums, setEnums] = useState<string[]>([])
    const addCurrentEnum = () => {
        if (currentEnum.trim().length > 0 && !enums.includes(currentEnum)) {
            setEnums([...enums, currentEnum.trim()])
        }
        setCurrentEnum("")
    }
    const removedEnums = state.isOpen && state.data !== null && isKpiQualitative(state.data) ? state.data.kpiQualitative.enums.filter(e => !enums.includes(e.value)) : []

    const quantitativeOk = type === Type.Quantitative && targetDate !== null && (targetMin !== null || targetMax !== null)
    const qualitativeOk = type === Type.Qualitative && enums.length > 0
    const ok = name.length > 0 && description.length > 0 && (quantitativeOk || qualitativeOk)

    const send = useAsync<any>({
        deferFn: async () => {
            if (state.data === null) {
                if (type === Type.Quantitative) {
                    return serverFetch("/api/kpi/quantitative", {
                        login,
                        method: "POST",
                        bodyFormat: "json",
                        body: {
                            name, description, unit, targetMin, targetMax,
                            targetDate: targetDate!.toISOString()
                        }
                    })
                } else if (type === Type.Qualitative) {
                    return serverFetch("/api/kpi/qualitative", {
                        login,
                        method: "POST",
                        bodyFormat: "json",
                        body: {
                            name, description, enums
                        }
                    })
                }
            } else {
                throw new Error("TODO") //TODO
            }
        },
        onResolve: () => {
            onSaved()
            state.close()
        }
    })

    const setData = send.setData
    useEffect(() => {
        if (state.isOpen) {
            if (state.data) {
                setName(state.data.name)
                setDescription(state.data.description)
                if (isKpiQuantitative(state.data)) {
                    setType(Type.Quantitative)
                    setUnit(state.data.kpiQuantitative.unit)
                    setTargetMin(state.data.kpiQuantitative.targetMin)
                    setTargetMax(state.data.kpiQuantitative.targetMax)
                    setTargetDate(new Date(state.data.kpiQuantitative.targetDate))
                } else if (isKpiQualitative(state.data)) {
                    setType(Type.Qualitative)
                    setCurrentEnum("")
                    setEnums(state.data.kpiQualitative.enums.map(e => e.value))
                }
            } else {
                setName("")
                setDescription("")
                setType(Type.Quantitative)
                setUnit("")
                setTargetMin(null)
                setTargetMax(null)
                setTargetDate(null)
                setCurrentEnum("")
                setEnums([])
            }
            setData(undefined)
        }
    }, [state, setName, setDescription, setType, setUnit, setTargetMin, setTargetMax, setTargetDate, setData])

    return <Dialog
        fullWidth
        maxWidth="md"
        open={state.isOpen}
        onClose={state.close}
    >
        {state.isOpen && <>
            <DialogTitle>{state.data ? "Modifica" : "Crea"} KPI</DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing={2} sx={{pt: 1}}>
                    <Grid item>
                        <TextField
                            fullWidth
                            value={name}
                            label="Nome"
                            inputProps={{maxLength: 128}}
                            onChange={e => setName(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            value={description}
                            label="Descrizione"
                            onChange={e => setDescription(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl disabled={state.data !== null} component="fieldset">
                            <FormLabel component="legend">Tipologia</FormLabel>
                            <RadioGroup row value={type} onChange={e => setType(e.target.value as Type)}>
                                <FormControlLabel value={Type.Quantitative} control={<Radio/>} label="Quantitativo"/>
                                <FormControlLabel value={Type.Qualitative} control={<Radio/>} label="Qualitativo"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {type === Type.Quantitative && <>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        fullWidth
                                        inputProps={{maxLength: 8}}
                                        value={unit}
                                        label="Unità"
                                        onChange={e => setUnit(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <MinMaxTextField
                                        label="Obbiettivo minimo"
                                        unit={unit}
                                        value={targetMin}
                                        setValue={setTargetMin}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <MinMaxTextField
                                        label="Obbiettivo massimo"
                                        unit={unit}
                                        value={targetMax}
                                        setValue={setTargetMax}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={it}>
                                        <DateTimePicker
                                            renderInput={(props) => <TextField fullWidth {...props} />}
                                            label="Data e ora obbiettivo"
                                            value={targetDate}
                                            onChange={setTargetDate}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>}
                    {type === Type.Qualitative && <>
                        <Grid item>
                            <TextField
                                fullWidth
                                inputProps={{maxLength: 32}}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <Button
                                            disabled={currentEnum.length === 0}
                                            startIcon={<Add/>}
                                            onClick={addCurrentEnum}
                                            children="Aggiungi"
                                        />
                                    </InputAdornment>
                                }}
                                value={currentEnum}
                                label="Aggiungi valore alfabeto"
                                onChange={e => setCurrentEnum(e.target.value)}
                                onKeyDown={e => {
                                    if (e.key === "Enter") {
                                        addCurrentEnum()
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}
                                component="ul"
                            >
                                {enums.map((e, i) => {
                                    return <ChipItem key={e}>
                                        <Chip label={e} onDelete={() => {
                                            enums.splice(i, 1)
                                            setEnums([...enums])
                                        }}/>
                                    </ChipItem>
                                })}
                            </Box>
                        </Grid>
                    </>}
                    <WrappableCollapse in={removedEnums.length > 0} createWrapper={params => <CollapsibleGridItem params={params}/>}>
                        <Alert severity="warning">
                            <AlertTitle>Attenzione!</AlertTitle>
                            Hai rimosso <b>{removedEnums.length}</b> elementi dall'alfabeto. Salvando verranno perse tutte le misurazioni associate!<br/>
                            <Link
                                color="inherit"
                                sx={{cursor: "pointer"}}
                                onClick={() => setEnums([...enums, ...removedEnums.map(e => e.value)])}
                                children="Ripristina elementi cancellati"
                            />
                        </Alert>
                    </WrappableCollapse>
                    <AsyncErrorAlert
                        state={send}
                        createWrapper={params => <CollapsibleGridItem params={params}/>}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={state.close}>Annulla</Button>
                <AsyncButton state={send} onClick={send.run} disabled={!ok}>
                    {state.data ? "Modifica" : "Crea"}
                </AsyncButton>
            </DialogActions>
        </>}
    </Dialog>
}

const ChipItem = styled('li')(({theme}) => ({
    margin: theme.spacing(0.5),
}));


function MinMaxTextField({label, unit, value, setValue}: {
    label: string
    unit: string
    value: number | null
    setValue: (value: number | null) => void
}) {
    return <TextField
        fullWidth
        type="number"
        label={label}
        InputProps={{
            startAdornment: unit ? <InputAdornment position="start">{unit}</InputAdornment> : undefined,
        }}
        value={value ?? ""}
        onChange={e => {
            if (e.target.value === "") {
                setValue(null)
            } else {
                setValue(parseFloat(e.target.value))
            }
        }}
    />
}