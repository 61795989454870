import {useCallback, useEffect, useMemo, useState} from "react";
import {useTheme} from "@mui/material";

type BaseDialogState<T> = {
    open: (data: T) => void
    close: () => void
}

export type OpenDialogState<T> = BaseDialogState<T> & {
    isOpen: true
    data: T
}

export type ClosedDialogState<T> = BaseDialogState<T> & {
    isOpen: false
    data: undefined
}

export type DialogState<T> = OpenDialogState<T> | ClosedDialogState<T>


export default function useDialogState<T>(initial?: T): DialogState<T> {
    const theme = useTheme()
    const transitionDuration = theme.transitions.duration.leavingScreen
    const [data, setData] = useState<T | undefined>(initial)
    const [isOpen, setOpen] = useState(initial !== undefined)

    const openDialog = useCallback((value: T) => {
        setData(value)
        setOpen(true)
    }, [setData, setOpen])

    useEffect(() => {
        if (!isOpen && data !== undefined) {
            const t = setTimeout(() => {
                setData(undefined)
            }, transitionDuration)
            return () => clearTimeout(t)
        }
    }, [isOpen, data, transitionDuration])

    return useMemo(() => ({
        isOpen,
        data,
        open: openDialog,
        close: () => setOpen(false)
    } as DialogState<T>), [isOpen, data, openDialog, setOpen])
}