import {Collapse, Theme, useTheme} from "@mui/material";
import React, {ComponentProps, CSSProperties, PropsWithChildren, ReactElement, useEffect, useState} from "react";

type WrapperSupportedCSSProp = "paddingTop" | "paddingBottom"

export type CreateWrapperParams = {
    children: ReactElement
    isOpen: boolean
    theme: Theme
    animationDuration: string
    easing: string
    buildTransition: (props: string[]) => string
    buildStyles: <P extends WrapperSupportedCSSProp>(props: P[], fallbacks: Pick<CSSProperties, P>) => CSSProperties
}

const CLOSED_SUPPORTED_PROP_VALUE: Pick<CSSProperties, WrapperSupportedCSSProp> = {
    paddingTop: 0,
    paddingBottom: 0
}

export default function WrappableCollapse<E extends PropsWithChildren<any>>({createWrapper, ...props}: {
    createWrapper?: (params: CreateWrapperParams) => ReactElement<E>
} & ComponentProps<typeof Collapse>) {

    const theme = useTheme()
    const [isOpen, setOpen] = useState(false)

    useEffect(() => {
        setOpen(props.in ?? false)
    }, [setOpen, props.in])

    const animationDuration = `${theme.transitions.duration.standard}ms`
    const easing = theme.transitions.easing.easeInOut
    const buildTransition = (props: string[]) => props.map(prop => `${prop} ${animationDuration} ${easing}`).join(", ")

    const realCreateWrapper = createWrapper ?? (props => <>{props.children}</>)

    return realCreateWrapper({
        isOpen,
        theme,
        animationDuration,
        easing,
        children: <Collapse {...props} in={isOpen}/>,
        buildTransition,
        buildStyles: (props, fallbacks) => {
            return {
                ...Object.fromEntries(props.map(item => {
                    return [item, isOpen ? fallbacks[item] : CLOSED_SUPPORTED_PROP_VALUE[item]]
                })),
                transition: buildTransition(props)
            }
        }
    })
}