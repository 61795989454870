import {AppBar, AppBarProps, Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import {useHistory} from "react-router-dom";
import {useState} from "react";
import {ArrowBack, ExitToApp, Menu as MenuIcon, MoreVert} from "@mui/icons-material";
import {useLoginInfo} from "../App";

export default function TopBar({menu, title, backTo, ...props}: AppBarProps & {
    menu?: () => void
    title?: string
    backTo?: string
}) {
    const history = useHistory()

    return <AppBar
        position="fixed"
        elevation={0}
        {...props}
    >
        <Toolbar>
            {menu && <IconButton
                size="large"
                edge="start"
                color="inherit"
                sx={{mr: 2}}
                onClick={menu}
            >
                <MenuIcon/>
            </IconButton>}

            {backTo && <IconButton
                color="inherit"
                edge="start"
                onClick={() => history.push(backTo)}
            >
                <ArrowBack/>
            </IconButton>}

            <Box sx={{flexGrow: 1}}>
                <Typography
                    onClick={() => history.push("/")}
                    variant="h6"
                    component="span"
                    sx={{cursor: "pointer"}}
                    children="VIR2EM"
                />
            </Box>

            <MoreMenu/>
        </Toolbar>
    </AppBar>
}

function MoreMenu() {
    const login = useLoginInfo()
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)

    return <>
        <IconButton onClick={e => setAnchorEl(e.currentTarget)} color="inherit">
            <MoreVert/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
        >
            <MenuItem onClick={() => login.logout()}>
                <ListItemIcon>
                    <ExitToApp fontSize="small"/>
                </ListItemIcon>
                <ListItemText primary="Logout"/>
            </MenuItem>
        </Menu>
    </>
}