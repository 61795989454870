import {DialogState} from "../utils/useDialogState";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {DBKpiMeasurement, isKpiMeasurementQualitative, isKpiMeasurementQuantitative} from "shared/dist/kpi";
import AsyncButton from "../components/async/AsyncButton";
import {useLoginInfo} from "../App";
import {useAsync} from "react-async";
import {serverFetch} from "../utils/fetch";
import {useEffect} from "react";
import AsyncErrorAlert from "../components/async/AsyncErrorAlert";


export default function KpiDeleteMeasurementsDialog<M extends DBKpiMeasurement>({state, onDeleted}: {
    state: DialogState<M[]>
    onDeleted: () => void
}) {
    const login = useLoginInfo()

    const del = useAsync({
        deferFn: async () => {
            if (state.data && state.data.length > 0) {
                const first = state.data[0]

                let type = null
                if (isKpiMeasurementQuantitative(first)) {
                    type = "quantitative"
                } else if (isKpiMeasurementQualitative(first)) {
                    type = "qualitative"
                }

                if (type) {
                    return serverFetch(`/api/kpi/${type}/measurements`, {
                        login,
                        method: "DELETE",
                        bodyFormat: "json",
                        body: {ids: state.data.map(it => it.id)}
                    })
                }
            }
        },
        onResolve: () => {
            onDeleted()
            state.close()
        }
    })

    const setData = del.setData
    useEffect(() => {
        if (state.isOpen) {
            setData(undefined)
        }
    }, [state.isOpen, setData])

    return <Dialog
        fullWidth
        maxWidth="xs"
        open={state.isOpen}
        onClose={state.close}
    >
        {state.isOpen && <>
            <DialogTitle>Rimuovi {state.data.length === 1 ? "misurazione" : "misurazioni"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Vuoi veramente rimuovere {state.data.length === 1 ? "questa misurazione?" : <><b>{state.data.length}</b> misurazioni?</>}
                </DialogContentText>
                <AsyncErrorAlert state={del}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={state.close}>Annulla</Button>
                <AsyncButton state={del} onClick={del.run}>Rimuovi</AsyncButton>
            </DialogActions>
        </>}
    </Dialog>
}