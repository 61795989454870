import {AsyncRejected, AsyncState} from "react-async";
import {CSSProperties, ReactElement, ReactNode} from "react";
import {Button, CircularProgress, Grid, Typography, useTheme} from "@mui/material";
import Centered from "../Centered";
import {Error as ErrorIcon} from "@mui/icons-material";
import MessageWithIcon, {MessageWithIconChildren} from "../MessageWithIcon";

type ErrorFooter = (err: Error) => MessageWithIconChildren

export default function AsyncPage<T>({state, children, text, errorFooter, ...rest}: {
    state: AsyncState<T>
    children: (data: T) => ReactElement
    text?: ReactNode
    errorFooter?: ErrorFooter
    style?: CSSProperties
}) {
    if (state.isPending) {
        return <Pending text={text} {...rest}/>
    } else if (state.isRejected) {
        return <Error state={state} errorFooter={errorFooter} {...rest}/>
    } else if (state.isFulfilled) {
        return children(state.data)
    } else {
        return null
    }
}

function Pending({text, style}: {
    text?: ReactNode
    style?: CSSProperties
}) {
    const theme = useTheme()

    return <Centered style={style}>
        <CircularProgress/>
        <Typography
            variant="subtitle2"
            style={{marginTop: theme.spacing(2)}}
            children={text}
        />
    </Centered>
}


function Error({state, errorFooter, style}: {
    state: AsyncRejected<any>
    errorFooter?: ErrorFooter
    style?: CSSProperties
}) {
    let footer = errorFooter ? errorFooter(state.error) : undefined
    if (!Array.isArray(footer)) {
        footer = [footer]
    }
    footer = [
        <Grid item>
            <Button
                onClick={state.reload}
                children="Riprova"
            />
        </Grid>,
        ...footer
    ]
    return <Centered style={style}>
        <MessageWithIcon
            Icon={ErrorIcon}
            message={state.error.message}
            children={footer}
        />
    </Centered>
}
