import {DialogState} from "../../utils/useDialogState";
import {useLoginInfo} from "../../App";
import {useCallback, useMemo, useState} from "react";
import {intlFormat} from "date-fns";
import {useAsync} from "react-async";
import {serverFetch} from "../../utils/fetch";
import KpiAddMeasurementDialog from "../../dialogs/KpiAddMeasurementDialog";
import {Checkbox, FormControlLabel, Grid, lighten, Tab, useTheme} from "@mui/material";
import TitleValue from "../../components/TitleValue";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import AspectRatioBox from "../../components/AspectRatioBox";
import AsyncPage from "../../components/async/AsyncPage";
import {Line} from "react-chartjs-2";
import {alpha} from "@mui/system/colorManipulator";
import {it} from "date-fns/locale";
import {DBKpi, DBKpiQuantitativeMeasurement, DBQuantitativeKpi} from "shared/dist/kpi";
import {indigo} from "@mui/material/colors";
import KpiMeasurementsTable from "../../components/KpiMeasurementsTable";

export function QuantitativeKpi({kpi, addMeasurementDialog}: {
    addMeasurementDialog: DialogState<DBKpi>
    kpi: DBQuantitativeKpi
}) {
    const login = useLoginInfo()
    const target = useMemo(() => {
        const ret = []
        let formattedDate = intlFormat(new Date(kpi.kpiQuantitative.targetDate), {
            dateStyle: "medium",
            timeStyle: "medium"
        } as any, {
            locale: "it"
        })
        ret.push(`Entro il ${formattedDate}`)
        if (kpi.kpiQuantitative.targetMin) {
            ret.push(`minimo ${kpi.kpiQuantitative.targetMin.toLocaleString("it")}`)
        }
        if (kpi.kpiQuantitative.targetMax) {
            ret.push(`massimo ${kpi.kpiQuantitative.targetMax.toLocaleString("it")}`)
        }
        return ret.join(", ")
    }, [kpi])

    const [tab, setTab] = useState<"chart" | "table">("chart")

    const measurementsAsync = useAsync<DBKpiQuantitativeMeasurement[]>(useCallback(async () => {
        return (await serverFetch(`/api/kpi/quantitative/${kpi.id}/measurements`, {login})).json()
    }, [login, kpi.id]))


    return <>
        <KpiAddMeasurementDialog state={addMeasurementDialog} onMeasurementAdded={measurementsAsync.reload}/>

        <Grid container direction="column">
            <Grid item>
                <TitleValue title="Tipo" value="Quantitativo"/>
            </Grid>
            <Grid item>
                <TitleValue title="Unità" value={kpi.kpiQuantitative.unit}/>
            </Grid>
            <Grid item>
                <TitleValue title="Obbiettivo" value={target}/>
            </Grid>
            <Grid item>
                <TabContext value={tab}>
                    <TabList onChange={(e, value) => setTab(value)}>
                        <Tab label="Grafico" value="chart"/>
                        <Tab label="Tabella" value="table"/>
                    </TabList>
                    <AspectRatioBox width="100%" aspectRatio={21 / 9}>
                        <AsyncPage
                            state={measurementsAsync}
                            text="Caricamento misurazioni..."
                            children={measurements => <>
                                <TabPanel value="chart" sx={{p: 0, pt: 1, height: "100%"}}>
                                    <QuantitativeKpiChart kpi={kpi} measurements={measurements}/>
                                </TabPanel>
                                <TabPanel value="table" sx={{p: 0, pt: 1, height: "100%"}}>
                                    <KpiMeasurementsTable
                                        colDef={{
                                            field: "value",
                                            valueFormatter: ({value}) => `${value} ${kpi.kpiQuantitative.unit}`
                                        }}
                                        measurements={measurements}
                                        reload={measurementsAsync.reload}
                                    />
                                </TabPanel>
                            </>}
                        />
                    </AspectRatioBox>
                </TabContext>
            </Grid>
        </Grid>
    </>
}

function QuantitativeKpiChart({kpi, measurements}: {
    kpi: DBQuantitativeKpi,
    measurements: DBKpiQuantitativeMeasurement[]
}) {
    const theme = useTheme()
    const [showTarget, setShowTarget] = useState(false)

    const targetDataset = useMemo(() => {
        const date = new Date(kpi.kpiQuantitative.targetDate).getTime()
        const data = []
        if (kpi.kpiQuantitative.targetMin) {
            data.push({
                x: date,
                y: kpi.kpiQuantitative.targetMin
            })
        }
        if (kpi.kpiQuantitative.targetMax) {
            data.push({
                x: date,
                y: kpi.kpiQuantitative.targetMax
            })
        }
        return data
    }, [kpi])

    return <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
        <div style={{flexGrow: 1, width: "100%", position: "relative"}}>
            <div style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0}}>
                <Line
                    data={{
                        datasets: [{
                            borderColor: theme.palette.primary.light,
                            label: "Dataset",
                            fill: "origin",
                            backgroundColor: alpha(lighten(theme.palette.primary.main, 0.7), 0.3),
                            data: measurements.map(m => ({
                                x: new Date(m.time).getTime(),
                                y: m.value
                            }))
                        },
                            {
                                borderColor: indigo["500"],
                                label: "Target",
                                fill: "origin",
                                data: showTarget ? targetDataset : []
                            }
                        ]
                    }}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                type: 'time',
                                adapters: {
                                    date: {
                                        locale: it
                                    }
                                },
                                time: {
                                    unit: "day"
                                }
                            },
                        },
                        plugins: {
                            legend: {
                                display: false
                            }
                        }
                    }}
                />
            </div>
        </div>
        <FormControlLabel
            control={<Checkbox checked={showTarget} onChange={(e, value) => setShowTarget(value)}/>}
            label="Mostra obiettivo nel grafico"
        />
    </div>
}