import TopBar from "./TopBar";
import {PropsWithChildren, useState} from "react";
import {Box, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, styled, Toolbar, useMediaQuery, useTheme} from "@mui/material";
import {QuestionAnswer, Timeline} from "@mui/icons-material";
import {useHistory, useRouteMatch} from "react-router-dom";

const DRAWER_WIDTH = 240
const DRAWER_BREAKPOINT = "sm" as const


const ToolbarDiv = styled('div')(({theme}) => ({
    ...theme.mixins.toolbar,
    height: theme.mixins.toolbar.minHeight,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}))

export default function Page({children, title, backTo}: PropsWithChildren<{
    title?: string
    backTo?: string
}>) {
    const theme = useTheme()
    const [mobileOpen, setMobileOpen] = useState(false)

    const isMobile = !useMediaQuery(theme.breakpoints.up(DRAWER_BREAKPOINT))

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const container = window !== undefined ? () => window.document.body : undefined
    return <Box sx={{display: 'flex', height: "100%"}}>
        <TopBar
            menu={isMobile ? handleDrawerToggle : undefined}
            title={title}
            backTo={backTo}
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
        />

        <Box
            component="nav"
            sx={{width: {sm: DRAWER_WIDTH}, flexShrink: {sm: 0}}}
            aria-label="mailbox folders"
        >
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', sm: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: DRAWER_WIDTH},
                }}
                children={<DrawerContent/>}
            />
            <Drawer
                open
                variant="permanent"
                sx={{
                    display: {xs: 'none', sm: 'block'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: DRAWER_WIDTH},
                }}
                children={<DrawerContent/>}
            />
        </Box>
        <Box sx={{width: "100%", flexGrow: 1, display: "flex", flexDirection: "column"}}>
            <Toolbar/>
            <Box component="main" sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                pt: 3,
                height: "100%",
                overflow: "auto"
            }}>
                <Box sx={{flexGrow: 1}}>
                    {children}
                </Box>
            </Box>
        </Box>
    </Box>
}

export function DrawerContent() {
    const match = useRouteMatch()
    const history = useHistory()

    return <div>
        <ToolbarDiv/>
        <Divider/>
        <List>
            <ListItem
                button
                selected={match.url.startsWith("/survey")}
                onClick={() => history.push("/survey")}
            >
                <ListItemIcon><QuestionAnswer/></ListItemIcon>
                <ListItemText primary="Questionario"/>
            </ListItem>

            <ListItem
                button
                selected={match.url.startsWith("/kpis")}
                onClick={() => history.push("/kpis")}
            >
                <ListItemIcon><Timeline/></ListItemIcon>
                <ListItemText primary="KPI"/>
            </ListItem>
        </List>
    </div>
}