"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isKpiMeasurementQualitative = exports.isKpiMeasurementQuantitative = exports.isKpiQualitative = exports.isKpiQuantitative = exports.KPIS_QUERY = void 0;
const client_1 = require("@prisma/client");
exports.KPIS_QUERY = client_1.Prisma.validator()({
    select: {
        id: true,
        name: true,
        description: true,
        kpiQuantitative: true,
        kpiQualitative: {
            include: {
                enums: true
            }
        }
    },
});
function isKpiQuantitative(kpi) {
    return kpi.kpiQuantitative !== null;
}
exports.isKpiQuantitative = isKpiQuantitative;
function isKpiQualitative(kpi) {
    return kpi.kpiQualitative !== null;
}
exports.isKpiQualitative = isKpiQualitative;
function isKpiMeasurementQuantitative(kpi) {
    return kpi.value !== undefined;
}
exports.isKpiMeasurementQuantitative = isKpiMeasurementQuantitative;
function isKpiMeasurementQualitative(kpi) {
    return kpi.enumId !== undefined;
}
exports.isKpiMeasurementQualitative = isKpiMeasurementQualitative;
