import {ComponentProps} from "react";
import {Grid} from "@mui/material";
import {CreateWrapperParams} from "./WrappableCollapse";

export default function CollapsibleGridItem({params, ...props}: {
    params: CreateWrapperParams
} & Omit<ComponentProps<typeof Grid>, "children">) {
    return <Grid
        item
        style={{
            ...params.buildStyles(["paddingBottom", "paddingTop"], {}),
            ...props.style,
        }}
        {...props}
        children={params.children}
    />
}