import {createTheme} from "@mui/material";

export const THEME = createTheme({
    palette: {
        primary: {
            main: "#9b0014"
        },
        secondary: {
            main: "#374856"
        }
    },
})