import {Button, Container, Grid, Typography} from "@mui/material";
import React from "react";
import {useHistory} from "react-router-dom";


export default function NotFound() {
    const history = useHistory()

    return <Container
        sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
        }}
        maxWidth="xs"
    >
        <Grid container direction="column" spacing={2} alignItems="center" justifyContent="center">
            <Grid item>
                <Typography variant="h1">404</Typography>
            </Grid>
            <Grid item>
                <Typography variant="h5" component="h2" gutterBottom>Pagina non trovata</Typography>
            </Grid>
            <Grid item>
                <Button
                    onClick={() => history.push("/")}
                    variant="contained"
                    children="Torna alla home"
                />
            </Grid>
        </Grid>
    </Container>
}