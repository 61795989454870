import {DialogState} from "../utils/useDialogState";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {DBKpi} from "shared/dist/kpi";
import AsyncButton from "../components/async/AsyncButton";
import {useLoginInfo} from "../App";
import {useAsync} from "react-async";
import {serverFetch} from "../utils/fetch";
import {useEffect} from "react";
import AsyncErrorAlert from "../components/async/AsyncErrorAlert";

export default function KpiDeleteDialog({state, onDeleted}: {
    state: DialogState<DBKpi>
    onDeleted: () => void
}) {
    const login = useLoginInfo()

    const del = useAsync({
        deferFn: async () => {
            if (state.data) {
                return serverFetch(`/api/kpi/${state.data.id}`, {
                    login,
                    method: "DELETE"
                })
            }
        },
        onResolve: () => {
            onDeleted()
            state.close()
        }
    })

    const setData = del.setData
    useEffect(() => {
        if (state.isOpen) {
            setData(undefined)
        }
    }, [state.isOpen, setData])

    return <Dialog
        fullWidth
        maxWidth="xs"
        open={state.isOpen}
        onClose={state.close}
    >
        {state.isOpen && <>
            <DialogTitle>Eliminazione KPI</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Vuoi veramente eliminare il KPI <b>{state.data.name}</b>?
                </DialogContentText>
                <DialogContentText>
                    Tutte le misurazioni associate verranno perdute.
                </DialogContentText>
                <AsyncErrorAlert state={del}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={state.close}>Annulla</Button>
                <AsyncButton state={del} onClick={del.run}>Elimina</AsyncButton>
            </DialogActions>
        </>}
    </Dialog>
}