import {LoginInfo} from "../App";

export type ServerRequestInit = Omit<RequestInit, "body"> & {
    login?: Pick<LoginInfo, "token"|"logout">
} & (
    { body?: RequestInit["body"], bodyFormat?: undefined } |
    { body: object, bodyFormat: "json" }
    )

/**
 * Helper function to send the server a request
 */
export async function serverFetch(request: RequestInfo, init: ServerRequestInit = {}) {
    const {body, ...rest} = init

    const newHeaders = new Headers(init.headers ?? {})
    if (init.login) {
        newHeaders.set("Authorization", "Bearer " + init.login.token)
    }

    let newBody
    if (init.bodyFormat === "json") {
        newHeaders.set("Content-Type", "application/json")
        newBody = JSON.stringify(init.body)
    } else {
        newBody = init.body
    }

    const response = await fetch(request, {
        ...rest,
        body: newBody,
        headers: newHeaders,
    })

    if (response.ok) {
        return response
    } else {
        if (response.status === 401 && init.login) {
            init.login.logout("Sessione scaduta")
        }
        throw new HttpException(response)
    }
}

export class HttpException extends Error {
    constructor(public readonly response: Response) {
        super(`Server has given status code ${response.status}`)
    }
}