import {useState} from "react";
import {DataGrid, GridColDef, GridFooter, GridFooterContainer, GridSelectionModel} from "@mui/x-data-grid";
import useDialogState from "../utils/useDialogState";
import KpiDeleteMeasurementsDialog from "../dialogs/KpiDeleteMeasurementsDialog";
import {intlFormat} from "date-fns";
import {Box, Button} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {DBKpiMeasurement} from "shared/dist/kpi";


export default function KpiMeasurementsTable<M extends DBKpiMeasurement>({colDef, measurements, reload}: {
    colDef: GridColDef,
    measurements: M[]
    reload: () => void
}) {
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
    const deleteDialog = useDialogState<M[]>()

    return <div style={{height: "100%", width: "100%"}}>
        <KpiDeleteMeasurementsDialog state={deleteDialog} onDeleted={reload}/>
        <DataGrid
            checkboxSelection
            selectionModel={selectionModel}
            onSelectionModelChange={value => setSelectionModel(value)}
            density="compact"
            rows={measurements}
            columns={[
                {
                    field: "time",
                    headerName: "Data e ora",
                    type: "dateTime",
                    width: 175,
                    valueGetter: ({value}) => new Date(value as string),
                    valueFormatter: ({value}) => intlFormat(value as Date, {
                        dateStyle: "medium",
                        timeStyle: "medium"
                    } as any),
                },
                {
                    headerName: "Valore",
                    flex: 1,
                    ...colDef,
                }
            ]}
            hideFooterSelectedRowCount
            components={{
                Footer: () => {
                    return <GridFooterContainer>
                        <Box sx={{ml: 2, mr: 2}}>
                            <Button
                                disabled={selectionModel.length === 0}
                                startIcon={<Delete/>}
                                onClick={() => deleteDialog.open(measurements.filter(it => selectionModel.includes(it.id)))}
                                children={`Rimuovi selezionati (${selectionModel.length})`}
                            />
                        </Box>
                        <GridFooter/>
                    </GridFooterContainer>
                }
            }}
        />
    </div>
}