import {Alert, AlertProps} from "@mui/material";
import React, {ComponentProps} from "react";
import {AsyncState} from "react-async";
import WrappableCollapse from "../WrappableCollapse";

export default function AsyncErrorAlert({state, override, createWrapper}: {
    state: AsyncState<any>
    override?: (error: any) => AlertProps|null
    createWrapper?: ComponentProps<typeof WrappableCollapse>["createWrapper"]
}) {
    const computed = state.isRejected ? ((override ? override(state.error) : null) ?? {
        children: state.error.message,
        severity: "error"
    } as const) : null
    return <WrappableCollapse in={state.isRejected} createWrapper={createWrapper}>
        <Alert {...computed} />
    </WrappableCollapse>
}