import { styled } from "@mui/material"

const Centered = styled('div')({
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
})

export default Centered