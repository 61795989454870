import Page from "../components/Page";
import {useAsync} from "react-async";
import {CSSProperties, ReactNode, useCallback, useEffect, useState} from "react";
import {serverFetch} from "../utils/fetch";
import {Question} from "@prisma/client";
import AsyncPage from "../components/async/AsyncPage";
import {Button, Container, FormControl, Grid, InputLabel, lighten, LinearProgress, OutlinedInput, Pagination, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useLoginInfo} from "../App";
import {Check, Error, Home, NavigateNext, Replay, Send, SentimentDissatisfied} from "@mui/icons-material";
import MessageWithIcon from "../components/MessageWithIcon";
import {PaginationItem} from "@mui/lab";
import Centered from "../components/Centered";
import {Prompt, useHistory} from "react-router-dom";

export default function Survey() {
    const login = useLoginInfo()
    const questions = useAsync<Question[]>(useCallback(async () => {
        return (await serverFetch("/api/survey", {login})).json()
    }, [login]))


    return <Page>
        <Container maxWidth={false}>
            <AsyncPage
                state={questions}
                text="Caricamento domande..."
                children={questions => <Questions questions={questions}/>}
            />
        </Container>
    </Page>
}

function Questions({questions}: { questions: Question[] }) {
    const history = useHistory()
    const theme = useTheme()
    const login = useLoginInfo()
    const [page, setPage] = useState(1)
    const [answers, setAnswers] = useState<Record<number, string>>({})

    const validPage = page !== undefined && page !== null && page > 0 && page <= questions.length + 1
    useEffect(() => {
        if (!validPage) {
            setPage(1)
        }
    }, [validPage, setPage])

    const sendAnswers = useAsync({
        deferFn: async () => {
            return serverFetch("/api/survey", {
                login,
                method: "POST",
                bodyFormat: "json",
                body: {
                    answers: Object.entries(answers).map(([id, answer]) => ({id, answer}))
                }
            })
        }
    })

    if (questions.length === 0 || !validPage) {
        return <MessageWithIcon
            Icon={SentimentDissatisfied}
            message="Nessuna domanda disponibile"
        />
    } else if (!sendAnswers.isInitial) {
        return <>
            <MyPrompt when={!sendAnswers.isResolved}/>
            <AsyncPage state={sendAnswers} text="Invio domande in corso...">{() => {
                return <MessageWithIcon Icon={Check} message="Risposte inviate correttamente">
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={<Home/>}
                            onClick={() => history.push("/")}
                            children="Torna alla home"
                        />
                    </Grid>
                </MessageWithIcon>
            }}</AsyncPage>
        </>
    } else {
        const question = questions[page - 1]

        return <>
            <MyPrompt/>
            <Grid container direction="column" spacing={2} height="100%" wrap="nowrap">
                <Grid item>
                    <LinearProgress
                        variant="determinate"
                        value={(page - 1) * 100 / questions.length}
                    />
                </Grid>
                <Grid item alignSelf="center">
                    <Typography>
                        {question && <>Domanda {page}/{questions.length}</>}
                        {!question && <>Riepilogo</>}
                    </Typography>
                </Grid>
                <Grid item style={{flexGrow: 1}}>
                    {question && <QuestionComponent
                        question={question}
                        answer={answers[question.id]}
                        onAnswerChange={answer => setAnswers({
                            ...answers,
                            [question.id]: answer
                        })}
                        goToNextPage={() => setPage(page + 1)}
                        nextPageText={page === questions.length ? "Riepilogo" : "Prossima domanda"}
                    />}
                    {!question && <Summary
                        questions={questions}
                        answers={answers}
                        setPage={setPage}
                        sendAnswers={() => sendAnswers.run()}
                    />}
                </Grid>

                <Grid item alignSelf="center">
                    <Pagination
                        onChange={(e, page) => setPage(page)}
                        page={page}
                        count={questions.length + 1}
                        variant="outlined"
                        renderItem={props => {
                            const sx: CSSProperties = {}
                            let text: ReactNode = props.page
                            if (props.type === "page") {
                                if (props.page === questions.length + 1) {
                                    text = "Riepilogo"
                                } else {
                                    const answer = answers[questions[props.page - 1].id]
                                    if (!isAnswerOk(answer)) {
                                        if (props.page < page) {
                                            sx.backgroundColor = lighten(theme.palette.error.light, 0.5)
                                        }
                                    } else {
                                        sx.backgroundColor = lighten(theme.palette.success.light, 0.5)
                                    }
                                }
                            }
                            return <PaginationItem
                                {...props}
                                sx={sx}
                                page={text}
                            />
                        }}
                    />
                </Grid>
            </Grid>
        </>
    }
}

function QuestionComponent({question, answer, onAnswerChange, goToNextPage, nextPageText}: {
    question: Question
    answer: string | undefined
    onAnswerChange: (answer: string) => void
    goToNextPage: () => void
    nextPageText: string
}) {
    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up("sm"))

    return <Grid container direction="column" spacing={2} height="100%" wrap="nowrap">
        <Grid item alignSelf="center">
            <Typography variant={isMobile ? "h6" : "h4"} align="center">
                {question.question}
            </Typography>
        </Grid>
        <Grid item sx={{flexGrow: 1, maxHeight: 350}}>
            <Container style={{paddingLeft: 0, paddingRight: 0, height: "100%"}}>
                <Grid container direction="column" spacing={2} sx={{height: "100%"}} wrap="nowrap">
                    <Grid item sx={{flexGrow: 1}}>
                        <FormControl fullWidth sx={{height: "100%"}}>
                            <InputLabel>Risposta</InputLabel>
                            <OutlinedInput
                                fullWidth
                                multiline
                                autoFocus
                                minRows={5}
                                sx={{height: "100%"}}
                                value={answer ?? ""}
                                onChange={e => onAnswerChange(e.target.value)}
                                label="Risposta"
                                inputProps={{
                                    sx: {
                                        height: "100%!important",
                                        minHeight: "calc(1em * 7)"
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            sx={{float: "right"}}
                            endIcon={<NavigateNext/>}
                            onClick={goToNextPage}
                            children={nextPageText}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    </Grid>
}

function Summary({questions, answers, setPage, sendAnswers}: {
    questions: Question[]
    answers: Record<number, string>
    setPage: (page: number) => void
    sendAnswers: () => void
}) {
    const firstUnansweredQuestion = questions.find(q => !isAnswerOk(answers[q.id]))
    const ok = firstUnansweredQuestion === undefined

    return <Centered>
        {ok && <MessageWithIcon
            Icon={Check}
            message="Hai risposto a tutte le domande, puoi procedere con l'invio!"
        >
            <Grid item>
                <Button
                    variant="contained"
                    startIcon={<Send/>}
                    onClick={sendAnswers}
                    children="Invia risposte"
                />
            </Grid>
        </MessageWithIcon>}

        {!ok && <MessageWithIcon
            Icon={Error}
            message="Manca la risposta a una o più domande! Torna indietro per rispondere."
        >
            <Grid item>
                <Button
                    variant="contained"
                    startIcon={<Replay/>}
                    onClick={() => setPage(questions.indexOf(firstUnansweredQuestion) + 1)}
                    children="Torna indietro"
                />
            </Grid>
        </MessageWithIcon>}
    </Centered>
}

function isAnswerOk(answer: string | undefined) {
    return answer !== undefined && answer.trim().length > 0
}

function MyPrompt({when}: { when?: boolean }) {
    return <Prompt
        when={when}
        message="Vuoi veramente uscire dalla pagina? Perderai tutte le risposte non inviate"
    />
}