import Page from "../../components/Page";
import {useAsync} from "react-async";
import {useCallback} from "react";
import {useLoginInfo} from "../../App";
import AsyncPage from "../../components/async/AsyncPage";
import {Accordion, AccordionDetails, AccordionSummary, Button, Container, Fab, Grid, Typography, useTheme} from "@mui/material";
import {Add, Delete, Edit, ExpandMore, ShowChart, Timeline} from "@mui/icons-material";
import {serverFetch} from "../../utils/fetch";
import {DBKpi, isKpiQualitative, isKpiQuantitative} from "shared/dist/kpi";
import KpiDialog from "../../dialogs/KpiDialog";
import useDialogState from "../../utils/useDialogState";
import 'chartjs-adapter-date-fns';
import {QuantitativeKpi} from "./QuantitativeKpi";
import {QualitativeKpi} from "./QualitativeKpi";
import KpiDeleteDialog from "../../dialogs/KpiDeleteDialog";

export default function KPIs() {
    const theme = useTheme()
    const login = useLoginInfo()
    const kpisAsync = useAsync<DBKpi[]>(useCallback(async () => {
        return (await serverFetch("/api/kpi", {login})).json()
    }, [login]))

    const dialog = useDialogState<DBKpi | null>()
    const addMeasurementDialog = useDialogState<DBKpi>()
    const deleteDialog = useDialogState<DBKpi>()

    return <Page>
        <AsyncPage state={kpisAsync}>{kpis => {
            return <Container maxWidth="md">
                <KpiDialog state={dialog} onSaved={kpisAsync.reload}/>
                <KpiDeleteDialog state={deleteDialog} onDeleted={kpisAsync.reload} />

                {kpis.map(kpi => {
                    return <Accordion TransitionProps={{unmountOnExit: true}} key={kpi.id}>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            {isKpiQuantitative(kpi) && <ShowChart/>}
                            {isKpiQualitative(kpi) && <Timeline/>}
                            <Typography sx={{ml: 1}}>{kpi.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    {isKpiQuantitative(kpi) && <QuantitativeKpi
                                        addMeasurementDialog={addMeasurementDialog}
                                        kpi={kpi}
                                    />}
                                    {isKpiQualitative(kpi) && <QualitativeKpi
                                        addMeasurementDialog={addMeasurementDialog}
                                        kpi={kpi}
                                    />}
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Button
                                                startIcon={<Add/>}
                                                onClick={() => addMeasurementDialog.open(kpi)}
                                                children="Aggiungi misurazione"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                startIcon={<Edit/>}
                                                onClick={() => dialog.open(kpi)}
                                                children="Modifica impostazioni"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                startIcon={<Delete/>}
                                                onClick={() => deleteDialog.open(kpi)}
                                                children="Elimina KPI"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                })}

                {/* ADD MARGIN */}
                <Fab sx={{
                    visibility: "hidden",
                    mb: 4,
                    mt: 2
                }}/>

                <Fab
                    color="primary"
                    sx={{
                        position: 'absolute',
                        bottom: theme.spacing(4),
                        right: theme.spacing(4),
                    }}
                    onClick={() => dialog.open(null)}
                    children={<Add/>}
                />
            </Container>
        }}</AsyncPage>
    </Page>
}

