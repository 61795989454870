import {ReactNode} from "react";
import {Typography} from "@mui/material";

export default function TitleValue({title, value}: {
    title: ReactNode
    value: ReactNode
}) {
    return <div style={{display: "flex"}}>
        <Typography>{title}:</Typography>&nbsp;
        <Typography sx={{color: "text.secondary"}}>{value}</Typography>
    </div>
}