import Page from "../components/Page";
import {EmojiPeople, QuestionAnswer, Timeline} from "@mui/icons-material";
import {Button, Grid} from "@mui/material";
import {useHistory} from "react-router-dom";
import MessageWithIcon from "../components/MessageWithIcon";

export default function Home() {
    const history = useHistory()

    return <Page>
        <MessageWithIcon
            Icon={EmojiPeople}
            message="Ciao! Per iniziare, seleziona una sezione."
        >
            <Grid item>
                <Button
                    onClick={() => history.push("/survey")}
                    variant="contained"
                    startIcon={<QuestionAnswer/>}
                    children="Questionario"
                />
            </Grid>
            <Grid item>
                <Button
                    onClick={() => history.push("/kpis")}
                    variant="contained"
                    startIcon={<Timeline/>}
                    children="KPI"
                />
            </Grid>
        </MessageWithIcon>
    </Page>
}