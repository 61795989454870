import {Alert, Container, Grid, TextField} from "@mui/material";
import {Redirect, useHistory} from "react-router-dom";
import queryString from "query-string";
import {StringParam, useQueryParam} from "use-query-params";
import {useEffect, useState} from "react";
import Centered from "../components/Centered";
import logoWide from "../resources/logo-wide.png"
import {useAsync} from "react-async";
import {HttpException, serverFetch} from "../utils/fetch";
import AsyncButton from "../components/async/AsyncButton";
import AsyncErrorAlert from "../components/async/AsyncErrorAlert";
import CollapsibleGridItem from "../components/CollapsibleGridItem";

export default function Login({onLoggedIn}: {
    onLoggedIn: (token: string) => void
}) {
    const history = useHistory()
    const [queryError, setQueryError] = useQueryParam('error', StringParam)
    const [continuePath] = useQueryParam('continue', StringParam)
    const [error, setError] = useState<string | undefined>()

    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")

    useEffect(() => {
        if (queryError) {
            setQueryError(undefined, "replaceIn")
            setError(queryError)
        }
    }, [queryError, setQueryError, setError])

    const doLogin = useAsync<{ token: string }>({
        deferFn: async () => {
            return (await serverFetch("/api/auth/login", {
                method: "POST",
                bodyFormat: "json",
                body: {login, password}
            })).json()
        },
        onResolve: res => {
            onLoggedIn(res.token)
            history.push(continuePath ?? "/")
        }
    })

    return <Container sx={{height: "100%"}} maxWidth="xs">
        <Centered>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <img alt="" src={logoWide} width="100%"/>
                </Grid>
                {error && <Grid item>
                    <Alert severity="warning" children={error}/>
                </Grid>}
                <Grid item>
                    <TextField
                        fullWidth
                        required
                        label="Login"
                        value={login}
                        onChange={e => setLogin(e.target.value)}
                        inputProps={{maxLength: 32}}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        required
                        label="Password"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                doLogin.run()
                            }
                        }}
                    />
                </Grid>
                <AsyncErrorAlert
                    createWrapper={params => <CollapsibleGridItem params={params}/>}
                    state={doLogin}
                    override={err => {
                        if(err instanceof HttpException && err.response.status === 401) {
                            return {
                                severity: "warning",
                                children: "Login o passowrd sbagliati"
                            }
                        }
                        return null
                    }}
                />
                <Grid item>
                    <AsyncButton
                        disabled={login === "" || password === ""}
                        state={doLogin}
                        sx={{float: "right"}}
                        variant="contained"
                        children="Accedi"
                    />
                </Grid>
            </Grid>
        </Centered>
    </Container>
}


export function RedirectToLogin({error}: { error?: string }) {
    const history = useHistory()
    const params = queryString.stringify({
        continue: history.location.pathname !== "/" ? history.location.pathname + history.location.search + history.location.hash : undefined,
        error: error
    })
    return <Redirect to={"/login" + (params.length > 0 ? `?${params}` : "")}/>
}