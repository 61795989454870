import {DialogState} from "../../utils/useDialogState";
import {useLoginInfo} from "../../App";
import {useCallback} from "react";
import {useAsync} from "react-async";
import {serverFetch} from "../../utils/fetch";
import KpiAddMeasurementDialog from "../../dialogs/KpiAddMeasurementDialog";
import {Box, Grid} from "@mui/material";
import TitleValue from "../../components/TitleValue";
import {DBKpi, DBKpiQualitativeMeasurement, DBQualitativeKpi} from "shared/dist/kpi";
import AsyncPage from "../../components/async/AsyncPage";
import AspectRatioBox from "../../components/AspectRatioBox";
import KpiMeasurementsTable from "../../components/KpiMeasurementsTable";

export function QualitativeKpi({kpi, addMeasurementDialog}: {
    addMeasurementDialog: DialogState<DBKpi>
    kpi: DBQualitativeKpi
}) {
    const login = useLoginInfo()

    const measurementsAsync = useAsync<DBKpiQualitativeMeasurement[]>(useCallback(async () => {
        return (await serverFetch(`/api/kpi/qualitative/${kpi.id}/measurements`, {login})).json()
    }, [login, kpi.id]))


    return <>
        <KpiAddMeasurementDialog state={addMeasurementDialog} onMeasurementAdded={measurementsAsync.reload}/>

        <Grid container direction="column">
            <Grid item>
                <TitleValue title="Tipo" value="Qualitativo"/>
            </Grid>
            <Grid item>
                <TitleValue title="Alfabeto" value={kpi.kpiQualitative.enums.map(e => e.value).join(", ")}/>
            </Grid>

            <Grid item>
                <AspectRatioBox width="100%" aspectRatio={21 / 9}>
                    <AsyncPage
                        state={measurementsAsync}
                        text="Caricamento misurazioni..."
                        children={measurements => <>
                            <Box sx={{mt: 1, height: "100%"}}>
                                <KpiMeasurementsTable
                                    colDef={{
                                        field: "enumId",
                                        valueFormatter: ({value}) => kpi.kpiQualitative.enums.find(e => e.id === value)?.value,
                                    }}
                                    measurements={measurements}
                                    reload={measurementsAsync.reload}
                                />
                            </Box>
                        </>}
                    />
                </AspectRatioBox>
            </Grid>
        </Grid>
    </>
}